import React, { useEffect, useState, useRef } from "react";

// vendor
import Lottie from 'react-lottie';
import { navigate } from "gatsby";
import { FiDownload, FiFileText } from "react-icons/fi";
import { BiCheck, BiDownload, BiFilm, BiListPlus, BiServer } from "react-icons/bi";
import ProgressBar from "@ramonak/react-progress-bar";
import io from "socket.io-client";
import Plyr from "react-plyr";
import { Tooltip } from "react-tippy";
import axios from "axios";

// core
import { getParamByName } from "@mattevans-dev/editsquare.core/utils/utils";

// components
import MockupHeader from "../components/mockup/header";
import MockupContainer from "../components/mockup/mockupContainer";
import { checkIcon } from "../lottie/animations";
import { isBrowser } from "../utils/utils";

const lottieOptions = {
    loop: false,  
    autoplay: true,
    animationData: checkIcon
};

const OrderSuccess = ({id}) => {    
    // get /:renderId from url
    const renderId = id;
    
    // TODO: if no id logic here...
    const [orderId, setOrderId] = useState(null);
    const [renderType, setRenderType] = useState(null);
    const [extOrderId, setExtOrderId] = useState(null);
    const [extCheckoutId, setCheckoutId] = useState(null);
    const [step, setStep] = useState(1);
    const [queueNumber, setQueueNumber] = useState(3);
    const [progressComplete, setProgressComplete] = useState(0);
    const [encodingComplete, setEncodingComplete] = useState(0);
    const [logs, setLog] = useState([]);
    const logElem = useRef(null);
    
    useEffect(() => {
        if(orderId == null) {
            axios({
                method: 'get',
                url: process.env.GATSBY_MOCKUP_API + '/renders/' + renderId
            })
            .then((res) => {
                if(res.data.render.awsRequestId) {
                    setOrderId(res.data.render.awsRequestId);
                }
            });
        }
    }, [step])

    useEffect(() => {
        if(logElem && logElem.current) {
            logElem.current.scrollTop = logElem.current.scrollHeight;
        }
    }, [logs])
    // on component load
    useEffect(() => {
        // check status of render
        axios({
            method: 'get',
            url: process.env.GATSBY_MOCKUP_API + '/renders/' + renderId
        })
        .then((res) => {
            if(res.data.render.renderStatus == "done") {
                setOrderId(res.data.render.awsRequestId);
                setRenderType(res.data.render.renderType);
                setExtOrderId(res.data.render.extOrderId);
                setCheckoutId(res.data.render.extCheckoutId);
                if(step !== 4) {
                    setStep(4);
                }
            } else if(res.data.render.renderStatus == "failed") {
                navigate(`/order-failed/${renderId}`)
            }
        });
        // connect to socket io server
        const socket = io(process.env.GATSBY_MOCKUP_API, {
            withCredentials: false
        }); // todo: make the socket server address dynamic
        socket.on("log", function(msg) {
            setLog(logs => [...logs, msg]);
        });
        socket.on("state", function(msg) {
            if(msg === "failed") {
                navigate(`/order-failed/${renderId}`)
            } else {
                var index = statusText.map(function(e) { return e.status; }).indexOf(msg);
                // if done, retrieve full render info.
                if(msg == 'done') {
                    // check status of render
                    axios({
                        method: 'get',
                        url: process.env.GATSBY_MOCKUP_API + '/renders/' + renderId
                    })
                    .then((res) => {
                        setOrderId(res.data.render.awsRequestId);
                        setRenderType(res.data.render.renderType);
                        setExtOrderId(res.data.render.extOrderId);
                        setCheckoutId(res.data.render.extCheckoutId);
                        setStep(index + 1);
                        setLog(logs => [...logs, "Setting state to " + msg]);
                    });
                } else {
                    setStep(index + 1);
                    setLog(logs => [...logs, "Setting state to " + msg]);
                }
            }
        });
        socket.on("render", function (msg) {
            // set step (in case we join half way through processing)
            if(step != 2) { setStep(2); }
            setProgressComplete(Math.floor(msg.percentage * 100));
        });
        socket.on("encode", function(msg) {
            if(step != 2) { setStep(3); }
            setEncodingComplete(Math.floor(msg.total * 100));
            const percentages = Object.entries(msg)
                .filter(([k, v]) => k !== 'total')
                .map(([k, v], i) => `${k}: ${Math.floor(v * 100)}%`);
            const log = `Encoding progress (${percentages.join(', ')})`;
            setLog(logs => [...logs, log]);
        })
        socket.on("disconnect", (reason) => {
            setLog(logs => [...logs, "Client disconnected..."]);
            if (reason === "io client disconnect") {
                setStep(4); // force to complete step.
            }
            // else the socket will automatically try to reconnect
        });
        // connect to render channel
        socket.emit('create', renderId);
    }, []);

    const statusText = [
        {
            'status': 'queued',
            'heading': 'Initialize Render',
            'content': <p>Acquiring a render agent from the cloud...</p>
        },
        {
            'status': 'processing',
            'heading': 'Rendering Video',
            'content': <p>Your video is now being rendered! This will be done shortly...</p>
        },
        {
            'status': 'encoding',
            'heading': 'Encoding Video',
            'content': <p>We are just finishing up &amp; compressing your video into multiple formats...</p>
        },
        {
            'status': 'done',
            'heading': 'Complete',
            'content': null
        }
    ];

    let qualityDownloads = [
        {icon: <div className="w-8 rounded bg-gray-800 py-1 px-1.5 text-white font-bold text-xs">SD</div>, label: <>Download 360p <span className="text-xxs">(.mp4)</span></>, fileName: 'output_360.mp4'}
    ];
    if(renderType !== "lq") {
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gray-800 py-1 px-1.5 text-white font-bold text-xs">SD</div>, label: <>Download 540p <span className="text-xxs">(.mp4)</span></>, fileName: 'output_540.mp4'});
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gray-800 py-1 px-1.5 text-white font-bold text-xs">HD</div>, label: <>Download 720p <span className="text-xxs">(.mp4)</span></>, fileName: 'output_720.mp4'});
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gray-800 py-1 px-1.5 text-white font-bold text-xs">HD</div>, label: <>Download 1080p <span className="text-xxs">(.mp4)</span></>, fileName: 'output_1080.mp4'});
    } else {
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gray-800 py-1 px-1.5 text-white font-bold text-xs">HD</div>, label: <>Download 720p <span className="text-xxs">(.mp4)</span></>, fileName: 'output_720.mp4'});
    }
    // Add ProRes files to array
    if(renderType === "uhd") {
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gradient-to-b from-indigo-600 to-indigo-700 py-1 px-1.5 text-white font-bold text-xs">4K</div>, label: <>Download 4k <span className="text-xxs">(.mov)</span></>, fileName: 'output.mov'});
    } else if (renderType === "hd") {
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gradient-to-b from-indigo-600 to-indigo-700 py-1 px-1.5 text-white font-bold text-xs">HD</div>, label: <>Download HD <span className="text-xxs">(.mov)</span></>, fileName: 'output.mov'});
    } else {
        qualityDownloads.unshift({icon: <div className="w-8 rounded bg-gradient-to-b from-indigo-600 to-indigo-700 py-1 px-1.5 text-white font-bold text-xs">HD</div>, label: <>Download 720p <span className="text-xxs">(.mov)</span></>, fileName: 'output.mov'});
    }
    const StepAwaitingIcon = ({ icon }) => (
        <div className="bg-white rounded-full border-2 border-gray-200 h-12 w-12 md:h-16 md:w-16 flex items-center justify-center p-2">
            {icon}
        </div>);

    const StepLoadingIcon = () => (
        <div className="bg-gray-200 rounded-full border-2 border-transparent h-12 w-12 md:h-16 md:w-16 flex items-center justify-center p-2">
            <svg className="animate-spin h-6 w-6 md:h-8 md:w-8 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
        </div>);

    const StepDoneIcon = () => (
        <div className="bg-white rounded-full h-12 w-12 md:h-16 md:w-16 flex items-center justify-center p-2 border-2 border-green-500">
            <BiCheck className={`h-6 w-6 md:h-8 md:w-8 text-green-500`} />
        </div>);

    const iconClasses = "h-6 w-6 md:h-8 md:w-8 text-gray-300";

    return (
        <MockupContainer>
            <MockupHeader hideSteps={true} />
            <div className="flex justify-center items-center flex-col">
                <div className="w-full bg-gray-100 p-10">
                    <div className="text-center mb-24">
                        <Lottie
                            options={lottieOptions}
                            height={95}
                            width={95}
                        />
                        <h1 className="text-3xl font-semibold my-1">Order Successful <span className="inline-block ml-0.5">🎉</span></h1>
                        { renderType === "lq" ? null : (<p className="text-gray-600 mb-1">Thank you for your purchase. We have taken payment of <span className="font-semibold">£0.00</span></p>)}
                        <p className="text-gray-600 mb-2">Your order no. is: <span className="font-semibold">{renderId}</span></p>
                        {step != 4 && (<p className="text-gray-600">We will email you once your video is ready,<br />or you can wait on this page and it will be ready shortly...</p>)}
                    </div>
                </div>

                
                { // render progress steps
                step != 4 ?
                <div className="renderProgress w-full max-w-180 -mt-24 px-8 mb-12">
                    <div className="bg-white shadow-lg rounded-xl p-8">
                        <h3 className="text-center text-2xl font-semibold leading-none mb-5">Render Progress</h3>
                        <div className="flex justify-center -mx-1 md:-mx-2 mb-3">
                            <div className={`px-1 md:px-2 ${step === 1 ? 'activeStep' : ''} `} onClick={() => setStep(1)}>
                                {step === 1 && (<StepLoadingIcon />)}
                                {step > 1 && (<StepDoneIcon />)}
                            </div>
                            <div className={`px-1 md:px-2 ${step === 2 ? 'activeStep' : ''} `} onClick={() => setStep(2)}>
                                {step === 1 && (<StepAwaitingIcon icon={<BiServer className={iconClasses} />} />)}
                                {step === 2 && (<StepLoadingIcon />)}
                                {step > 2 && (<StepDoneIcon />)}
                            </div>

                            <div className={`px-1 md:px-2 ${step === 3 ? 'activeStep' : ''} `} onClick={() => setStep(3)}>
                                {step <= 2 && (<StepAwaitingIcon icon={<BiFilm className={iconClasses} />} />)}
                                {step === 3 && (<StepLoadingIcon />)}
                                {step === 4 && (<StepDoneIcon />)}
                            </div>

                            <div className={`px-1 md:px-2 ${step === 4 ? 'activeStep' : ''} `} onClick={() => setStep(4)}>
                                {step <= 3 && (<StepAwaitingIcon icon={<BiDownload className={iconClasses} />} />)}
                                {step > 3 && (<StepDoneIcon />)}
                            </div>
                        </div>

                        <div className="w-full pt-1.5">
                            <div className={`arrow_box_render p-7 rounded`}>
                                <h3 className="text-indigo-600 font-semibold text-lg mb-0.5">
                                    {statusText[step - 1].heading}
                                </h3>
                                <div className="text-gray-700">{statusText[step - 1].content}</div>

                                {/* Progress Bars */}
                                {step === 2 && (
                                    <div className="w-full mt-4"><ProgressBar completed={progressComplete} labelSize="12px" bgColor="#5a67d8" borderRadius="500px" baseBgColor="#e2e8f0" /></div>
                                )}
                                {step === 3 && (
                                    <div className="w-full mt-4"><ProgressBar completed={encodingComplete} labelSize="12px" bgColor="#5a67d8" borderRadius="500px" baseBgColor="#e2e8f0" /></div>
                                )}
                            </div>
                        </div>
                    </div>
                    {step !== 4 && (
                    <div className="mt-5 p-7 text-gray-300 bg-gray-900 h-44 overflow-y-scroll text-sm" ref={logElem}>
                        {logs.map((msg, i) => (<p key={i}>{msg}</p>))}
                    </div>
                    )}
                </div>

                : 
                // Finished Rendering View
                <div className="renderProgress w-full max-w-240 -mt-24 px-8 mb-12">
                    <div className="bg-white shadow-lg rounded-xl p-8">
                    <div className="videoContainer">
                        <div className={"my-2"}>
                            <Plyr
                            type="video"
                            sources={
                                (renderType === "lq" ? [360, 720] : [1080, 720, 540, 360]).map((o) => (
                                    {
                                        src: `https://api.mockupclips.com/videos/${orderId}/output_${o}.mp4`,
                                        size: o + "",
                                        type: "video/mp4"
                                    }
                                ))
                            }
                            controls={['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen']}
                            settings={['quality']}
                            />
                        </div>
                        <div className="flex justify-end">
                            <a target="_blank" href={`https://my.paddle.com/receipt/${extOrderId}/${extCheckoutId}`} className="inline-flex items-center justify-center text-center border rounded text-sm py-2 px-4 cursor-pointer text-gray-600 border-gray-300 shadow-sm transition duration-200 hover:border-gray-400"><FiFileText className="mr-1" />Download Receipt</a>
                            <Tooltip
                                html={
                                <ul className="w-52">
                                    {qualityDownloads.map((o, i) => (<li className="hover:bg-indigo-100 hover:text-indigo-600 rounded-r" key={i}><a className="flex items-center py-0.5" href={`https://api.mockupclips.com/videos/${orderId}/${o.fileName}`}><div className="mr-2">{o.icon}</div><div>{o.label}</div></a></li>))}                                </ul>
                                }
                                distance={15}
                                arrow={true}
                                interactive={true}
                                theme="light"
                                trigger="click"
                                position="top"
                                size="small"
                                style={{ display: 'flex' }}>
                                <button className="ml-2 flex items-center justify-center cursor-pointer text-center border border-green-500 rounded text-sm py-2 px-4 bg-gradient-to-b from-green-500 to-green-600 text-white hover:from-green-500 hover:to-green-500"><FiDownload className="mr-1" />Download Video</button>
                            </Tooltip>
                        </div>
                    </div>
                    </div>
                </div>
                }
            </div>
        </MockupContainer>
    )
}
export default OrderSuccess;